<template>
  <v-container fluid>
    <v-row>
      <v-col class="pa-0 ma-0">
        <v-container v-if="!isLoadingData" fluid>
          <v-row>
            <v-col>
              <calendesk-information-message
                v-if="staticError"
                color="red-light"
                additional-class="danger--text"
                icon-color="danger"
                icon="$alert"
              >
                {{ staticError }}
              </calendesk-information-message>
              <v-stepper
                v-if="!isLoadingData"
                v-model="stepperStatus"
                class="snow_gray"
                vertical
              >
                <template v-for="step in steps">
                  <v-stepper-step
                    :key="step.num"
                    color="blue"
                    :step="step.num"
                    :complete="
                      step.num < stepperStatus || stepperStatus === steps.length
                    "
                  >
                    {{ $trans(step.title) }}
                  </v-stepper-step>
                  <v-stepper-content
                    :key="step.num + '_content'"
                    :step="step.num"
                  >
                    <v-card
                      color="white"
                      outlined
                      class="text-body-2 pa-2 mb-4"
                    >
                      {{ $trans("stripe_info_step_" + step.num) }}
                    </v-card>
                    <div class="mt-8">
                      <v-btn
                        v-if="stepperStatus === step.num"
                        color="blue"
                        small
                        outlined
                        :loading="buttonLoading"
                        :disabled="buttonLoading"
                        @click="step.action"
                      >
                        {{ $trans(step.buttonText) }}
                      </v-btn>
                    </div>
                  </v-stepper-content>
                </template>
              </v-stepper>
            </v-col>
          </v-row>
          <v-row v-if="stripeAccount">
            <v-col class="d-flex align-center font-weight-bold pb-0 mb-0">
              <div @click="$helpers.copyAndNotify(stripeAccount.id)">
                {{ $trans("stripe_id_label") }}: {{ stripeAccount.id }}
              </div>
              <v-btn
                class="mr-4"
                color="blue"
                icon
                @click.stop="$helpers.copyAndNotify(stripeAccount.id)"
              >
                <v-icon>$copy</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex align-center" cols="12" md="6">
              <v-btn
                v-if="stepperStatus > 1"
                :disabled="isSavingSettings"
                color="error"
                outlined
                text
                @click="disconnectAccount"
              >
                {{ $trans("disconnect_stripe_account") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-progress-linear
          v-else
          :active="isLoadingData"
          :indeterminate="isLoadingData"
          color="primary accent-4"
          absolute
          top
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import sharedConfirmDialogTypes from "@/lib/calendesk-js-library/components/confirmDialogs/sharedConfirmDialogTypes";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "StripeIntegration",
  components: { CalendeskInformationMessage },
  mixins: [sharedActions],
  data() {
    return {
      isLoadingData: false,
      staticError: null,
      buttonLoading: false,
      paymentMethodBancontact: false,
      paymentMethodEps: false,
      paymentMethodGiropay: false,
      paymentMethodIdeal: false,
      paymentMethodSofort: false,
      isSavingSettings: false,
      steps: [
        {
          num: 1,
          title: "stripe_title_step_1",
          buttonText: "stripe_title_step_1",
          action: this.createAccount,
        },
        {
          num: 2,
          title: "stripe_title_step_2",
          buttonText: "go_to_stripe",
          action: this.createAccount,
        },
        {
          num: 3,
          title: "stripe_title_step_3",
          buttonText: "go_to_stripe",
          action: this.goToStripe,
        },
        {
          num: 4,
          title: "stripe_title_step_4",
          buttonText: "go_to_stripe",
          action: this.goToStripe,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      stripeAccount: "stripe/getAccount",
      currentTenantPlan: "packages/getCurrentTenantPlan",
      adminConfiguration: "setup/getAdminConfiguration",
    }),
    stepperStatus() {
      if (!this.stripeAccount) {
        return 1;
      }
      if (!this.stripeAccount.details_submitted) {
        return 2;
      }
      if (
        this.stripeAccount.details_submitted &&
        !this.stripeAccount.charges_enabled
      ) {
        return 3;
      }
      if (this.stripeAccount.charges_enabled) {
        return 4;
      }

      return 2;
    },
  },
  created() {
    this.isLoadingData = true;
    this.getAccount()
      .finally(() => {
        this.isLoadingData = false;
      })
      .catch(() => {
        // Silent, no account
      });
  },
  methods: {
    ...mapActions({
      getAccount: "stripe/fetchAccount",
      deleteStripeAccount: "stripe/deleteAccount",
      createStripeAccount: "stripe/createAccount",
      updateConfiguration: "setup/updateConfiguration",
    }),
    createAccount() {
      this.staticError = null;

      this.buttonLoading = true;

      pushEvent("createStripeAccount");

      this.createStripeAccount({})
        .then((response) => {
          window.location = response.url;
        })
        .catch((error) => {
          errorNotification("stripe_connect_account_failed", error, false);
          this.buttonLoading = false;
        });
    },
    goToStripe() {
      pushEvent("goToStripe");

      this.buttonLoading = true;
      window.location = "https://stripe.com";
    },
    disconnectAccount() {
      pushEvent("disconnectStripeAccount");

      this.openConfirmDialog({
        type: sharedConfirmDialogTypes.COMMON,
        confirmAction: () => {
          this.setCommonDialogLoader(true);
          this.deleteStripeAccount()
            .then(() => {
              successNotification("disconnect_stripe_account_success");
            })
            .catch((error) => {
              errorNotification(null, error);
            })
            .finally(() => {
              this.setCommonDialogLoader(false);
              this.closeConfirmDialog();
            });
        },
      });
    },
  },
};
</script>
<style lang="scss">
.stripe__not_available .col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.stripe__not_available {
  padding: 12px;
}

.stripe__not_available-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--v-accent-base);
  border-radius: 8px;

  & > span,
  & > .v-btn {
    margin: 1rem 0;
  }
}
</style>
